// createLead

import { RepositoryFactory } from "../../repository/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("newLead");

export const createNewLead =
    ({ obj, onSuccess }) =>
    async (dispatch) => {
        await dispatch(createLoader(true));
        if (obj.bootCheck) {
            onSuccess();
            dispatch({
                type: "CREATE_RESPONSE",
                payload: "data",
            });
            await dispatch(createLoader(false));
        } else {
            try {
                let { data } = await LeadRepo.createNewLead(obj);
                if (data) {
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: data,
                    });
                    onSuccess();
                    await dispatch(createLoader(false));
                } else {
                    await dispatch(createLoader(false));
                }
            } catch (e) {
                // history.push("/thankyou");
                if (e.response.data.error == "Conflict - duplicate") {
                    onSuccess();
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: "data",
                    });
                    await dispatch(createLoader(false));
                } else {
                    let error = e.response.data.error
                        .match(/'([^']+)'/)[0]
                        .slice(1, -1);
                    dispatch({
                        type: "ERROR_RESPONSE",
                        payload: { [error]: true },
                    });
                    await dispatch(createLoader(false));
                }
            }
        }
    };
export const setErrorResponse = () => async (dispatch) => {
    dispatch({
        type: "ERROR_RESPONSE",
        payload: null,
    });
};
export const createLoader = (val) => async (dispatch) => {
    dispatch({
        type: "CREATE_LOADER",
        payload: val,
    });
};
