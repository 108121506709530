const initialState = {
    loading: false,
    create_response: null,
    error_response: null,
};
export default function newLeadReducers(state = initialState, action) {
    switch (action.type) {
        case "CREATE_LOADER":
            return {
                ...state,
                loading: action.payload,
            };
        case "CREATE_RESPONSE":
            return {
                ...state,
                create_response: action.payload,
            };
        case "ERROR_RESPONSE":
            return {
                ...state,
                error_response: action.payload,
            };
        default:
            return { ...state };
    }
}
