// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import newLeadReducers from "./leadReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    lead: newLeadReducers,
});

export default rootReducer;
