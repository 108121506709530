import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import MainView from "../views/MainView";
import ThankYou from "../views/ThankYou";
import ThankYouUnderAge from "../views/ThankYouUnderAge";
import VialuxuryV2 from "../views/VialuxuryV2";

let routes = [
    // {
    // 	path: '/auth',
    // 	component: AuthView,
    // 	layout: 'auth',
    // },
    // {
    //     path: "/",
    //     component: Home,
    //     layout: "main",
    // },
    // {
    //     path: "/thankyou",
    //     component: ThankYouUnderAge,
    //     layout: "main",
    // },
    // {
    //     path: "/v2",
    //     component: VialuxuryV2,
    //     layout: "main",
    // },
];
export default routes;
